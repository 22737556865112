<template>
  <div>
    <div
      ref="container"
      class="univer-container"
      :class="{
        'hide-menus': hideMenus,
        'hide-formula-bar': hideFormulaBar,
        'hide-footer': hideFooter,
      }"
      :style="{ height }"
    />
  </div>
</template>

<script>
import { useDebounceFn } from "@vueuse/core"
import lodashReplacementHelper from "@/helpers/lodash_replacement_helpers"


const { Univer, LocaleType, Tools, UniverInstanceType, FUniver } = window.UniverCore
const { defaultTheme } = window.UniverDesign
const { UniverRenderEnginePlugin } = window.UniverEngineRender
const { UniverFormulaEnginePlugin } = window.UniverEngineFormula
const { UniverUIPlugin } = window.UniverUi
const { UniverSheetsPlugin } = window.UniverSheets
const { UniverSheetsUIPlugin } = window.UniverSheetsUi
const { UniverDocsPlugin } = window.UniverDocs
const { UniverDocsUIPlugin } = window.UniverDocsUi
const { UniverSheetsFormulaPlugin } = window.UniverSheetsFormula
const { UniverSheetsFormulaUIPlugin } = window.UniverSheetsFormulaUi
const { UniverSheetsNumfmtPlugin } = window.UniverSheetsNumfmt
const { UniverSheetsNumfmtUIPlugin } = window.UniverSheetsNumfmtUi

const UniverSheetsUiEnUS  = window.UniverSheetsUiEnUS
const UniverSheetsFormulaUiEnUS  = window.UniverSheetsFormulaUiEnUS
const UniverUiEnUS  = window.UniverUiEnUS
const UniverDocsUiEnUS  = window.UniverDocsUiEnUS
const UniverDesignEnUS  = window.UniverDesignEnUS

export default {
  name: "SpreadSheet",
  props: {
    // workbook data
    data: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideMenus: {
      type: Boolean,
      default: false,
    },
    hideFormulaBar: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "500px",
    },
    updateOnDataChanges: {
      type: Boolean,
      default: false,
    },
    emitChanges: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    // watch data change, and re-create univer instance
    data: {
      handler (val, oldVal) {
        // console.log("data changed")

        if(JSON.stringify(val) === JSON.stringify(oldVal)) return
        // console.log("content changed")

        // return
        if(!this.updateOnDataChanges) return
        // console.log("updateOnDataChanges")
        // if(this.univer) this.destroyUniver()
        this.init(val)
      },
      immediate: true,
      deep: true,
    },
  },
  data () {
    return {
      // univer instance
      univer: null,
      // workbook instance
      workbook: null,
      debouncedSave: useDebounceFn(() => {
        this.saveIfSheetsHasChanged()
      }, 500, { maxWait: 5000 }),
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
    this.destroyUniver()
  },
  methods: {
    /**
     * Initialize univer instance and workbook instance
     * @param data {IWorkbookData} document see https://univer.work/api/core/interfaces/IWorkbookData.html
     */
    init (data = this.data) {
      const univer = new Univer({
        theme: defaultTheme,
        locale: LocaleType.EN_US,
        locales: {
          [LocaleType.EN_US]: Tools.deepMerge(
            UniverDocsUiEnUS,
            UniverSheetsUiEnUS,
            UniverSheetsFormulaUiEnUS,
            UniverUiEnUS,
            UniverDesignEnUS,
          ),
        },
      })
      this.univer = univer

      univer.registerPlugin(UniverRenderEnginePlugin)
      univer.registerPlugin(UniverFormulaEnginePlugin)

      univer.registerPlugin(UniverUIPlugin, {
        container: this.$refs.container,
        contextMenu: true,
      })

      univer.registerPlugin(UniverDocsPlugin, {
        hasScroll: false,
      })
      univer.registerPlugin(UniverDocsUIPlugin)

      univer.registerPlugin(UniverSheetsPlugin)
      univer.registerPlugin(UniverSheetsUIPlugin)
      univer.registerPlugin(UniverSheetsFormulaPlugin)
      univer.registerPlugin(UniverSheetsFormulaUIPlugin)

      univer.registerPlugin(UniverSheetsNumfmtPlugin)
      univer.registerPlugin(UniverSheetsNumfmtUIPlugin)

      // create workbook instance
      // We have to deep clone the data to avoid the data children being modified by reference
      this.workbook = univer.createUnit(UniverInstanceType.UNIVER_SHEET, lodashReplacementHelper.deepClone(data))

      // set workbook editable or not
      // This is a workaround using permissions.
      // A dedicated solution may be implemented in the future: https://github.com/dream-num/univer/issues/2585
      const univerAPI = FUniver.newAPI(this.univer)
      univerAPI.getActiveWorkbook().setEditable(!this.readonly)

      // listen for changes
      if(this.readonly) return
      univerAPI.onCommandExecuted((command) => {
        const commandType = command.id.split(".")[1] // "operation" or "mutation"
        const commandDomain = command.id.split(".")[0] // "formula", "doc", "sheet",...
        if (commandType === "mutation" && commandDomain !== "formula") {
          // console.log(command)
          // this.save()
          this.debouncedSave()
        }
      })
    },
    /**
     * Destroy univer instance and workbook instance
     */
    destroyUniver () {
      this.univer?.dispose()
      this.univer = null
      this.workbook = null
    },
    /**
     * Get workbook data
     */
    getData () {
      if (!this.workbook) {
        throw new Error("Workbook is not initialized")
      }
      return this.workbook.save()
    },

    isEmpty(data) {
      if (!data?.sheets) return true

      const sheets = Object.values(data.sheets)

      if (sheets.length === 0) return true

      return sheets.every(sheet => {
        const cellData = sheet.cellData
        if (cellData.length === 0) return true

        return Object.values(cellData).every(cell =>
          Object.values(cell).every(value => value.v === "")
        )
      })
    },

    saveIfSheetsHasChanged() {
      if(!this.emitChanges) return

      const newData = this.getData()
      const newDataIsEmpty = this.isEmpty(newData)
      const dataIsEmpty = this.isEmpty(this.data)

      if (dataIsEmpty && newDataIsEmpty) return

      this.$emit("update:data", newData)
      this.$emit("change", newData)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.univer-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.hide-menus :deep(.univer-toolbar) {
  display: none ;
}

.hide-formula-bar :deep(.univer-formula-box) {
  display: none ;
}

.hide-footer :deep(footer) {
  display: none ;
}

/* Also hide the menubar */
:global(.univer-menubar) {
  display: none;
}
</style>

<style>
.univer-input-number-input, .univer-input {
  height: inherit !important;
}
</style>
